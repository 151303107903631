/**
 * Удаление лоадера из dom дерева после загрузки страницы
 * Скрипт отрабатывает в любом случае, даже если есть ошибки в JS
 */
window.onload = function() {
    $('body').removeClass('loaded');
    let loader = document.querySelector('.loader');
    loader.parentNode.removeChild(loader);
};

$(document).ready(function() {
    /** Глобальные переменные */
    var WIN = $(window),
        DOC = $(document),
        HTML = $('html'),
        BODY = $('body');



    /** mobile-menu toggler for open/close **/
    $('[data-mobile-menu]').on('click', function(event) {
        event.preventDefault();
        $(this).toggleClass('active');
        $('.header-menu').toggleClass('opened');
    });

    /** end mobile-menu toggler for open/close **/




    /** обрезка текстовых привью (количество символов береться из дата атрибута) **/
    function dataCutText() {
        var shortText= $('[data-cut-text]');
        shortText.each(function(){
            var size = $(this).data('cutText'),
                elementText = $.trim($(this).text());
            if(elementText.length > size){
                $(this).text(elementText.slice(0, size) + ' ...');
            }
        });
    }
    dataCutText();


    /**
     * Инициализация поля выбора даты
     * Используется в формах, которые подгружаются на ajax
     * Для этого вынесен в функцию для использования внутри ajaxSuccess
     **/
    function dateInit() {
        let input_date = $('.datepicker-input');
        if (input_date.length > 0 ) {
            if (!$('.datepickers-container').length) {
                var datelang = HTML.attr('lang');
                input_date.datepicker({
                    autoClose: true,
                    dateFormat: 'dd.mm.yyyy',
                    language: datelang
                });
            }
        }
    }

    // console.log(HTML.attr('lang'));

    dateInit();
    /** End **/




    /** Инициализация типовых модалок **/
    $('[data-modal]').magnificPopup({
        type: 'ajax',
        removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        callbacks: {
            parseAjax: function(mfpResponse) {
                mfpResponse.data = "<div class='wrapping'>" + mfpResponse.data + "</div>";
            },
            ajaxContentAdded: function() {
                this.content;
            },
            beforeOpen: function() {
                $('body').addClass('blur');
            },
            beforeClose: function() {
                $('body').removeClass('blur');
            }
        }
    });
    /** End Инициализация модалок **/

    /**
     * Иниализация отложенной подгрузки изображений + замена битого изображения логотипом проекта
     * https://skeleton.gitbook.io/docs/js/jquery-lazy
     **/
    function lazyLoad() {
        $('[data-lazy] img[data-src]').Lazy({
            scrollDirection: 'vertical',
            effect: "fadeIn",
            effectTime: 1000,
            onError: function(el) {
                el.attr("src","/front/img/logos/logo.png").parent().addClass('no-photo');
            }
        });
    }
    lazyLoad();

    /** Language select **/
    DOC.on('click', '.lang-select', function () {
        $(this).toggleClass('open');
    });
    $(".lang-select .lang-select__item").click(function () {
        $(this).siblings(".lang-select__item").removeClass('active');
        $(this).toggleClass('active')
    });



    /** Событие срабатывает в случае успешного выполнения ajax запроса **/
    $(document).on('ajaxFail ajaxDone', function() {
        dateInit();
    });
    /** Раскрытие меню провайдеров в футере **/


});
